import { set } from 'date-fns';
import { react, useEffect, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { FormattedMessage } from "react-intl";

const ModalGLPD = () => {
    const [divGLPD, setDivGLPD] = useState(false);

    useEffect(() => {
    const glpdAcept = localStorage.getItem('glpdAcept');
    if (glpdAcept === null) {
        setDivGLPD(true);
    }
    }, []);

    const setglpdAcept = () => {
        localStorage.setItem('glpdAcept', 'true');
        setDivGLPD(false);
    };

    const GLPD = () => {
        if (divGLPD === false) {
            return null;
        } else {
            return (
                <div 
                className='divModalGLPD'
                >
                    <div 
                    className='divModalGLPD-content'
                    >
                    <FormattedMessage id="GLPD" defaultMessage="We use essential cookies and similar technologies in accordance with our Privacy Policy and, to continue browsing, you agree with these conditions. " />
                    <br /><p />
                    <Button onClick={() => setglpdAcept()}><FormattedMessage id="To accept" defaultMessage="To accept" /></Button>           
                    </div>
                </div>
            );
    }
};
        
    return (
        <>
        <GLPD />
        </>
    );
};

export default ModalGLPD;