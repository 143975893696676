// ** React Imports
import { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '../../assets/data/locales/en.json'
import messagesEs from '../../assets/data/locales/es.json'
import messagesBr from '../../assets/data/locales/br.json'

// ** User Language Data
import userMessagesEn from '../../@core/assets/data/locales/en.json'
import userMessagesBr from '../../@core/assets/data/locales/br.json'
import userMessagesES from '../../@core/assets/data/locales/es.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  es: { ...messagesEs, ...userMessagesES },
  br: { ...messagesBr, ...userMessagesBr }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const language = localStorage.getItem('language') || 'en'
  const [locale, setLocale] = useState(language)
  const [messages, setMessages] = useState(menuMessages[language])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
    localStorage.setItem('language', lang)
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
