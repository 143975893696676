import logo_push from "../../../assets/images/pages/logo_push.png";
import { getYear } from "date-fns";
import { Zap, Mail, Phone } from "react-feather";
const FooterExterno = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "5px",
          backgroundColor: "#185a9d"
        }}
      ></div>
      <div className="bg-footer">
        <img src={logo_push} className="logo-footer"></img>
        <div className="align-info">
          <Mail size={17} /> info@pushdentaleducation.com{" "}
        </div>
        <div className="align-info">
          <Phone size={17} /> +55 (41) 99266-9393
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          backgroundColor: "#121111",
          padding: "1%",
          color: "#d2d2d2"
        }}
      >
        <div>
          <div className="align-coperigth">
            Copyright © {getYear(new Date())} Push Education design por{" "}
            <h6
              className="gradient"
              style={{ marginLeft: "5px", marginRight: "5px"}}
            >
              {" "}
              <a href="https://newvibes.com.br/" target="_blanc">
                New Vibes Digital
              </a>
            </h6>{" "}
            & developed by <h6 style={{ marginLeft: "5px", color: "#FFBC00"}}>DATASSIVE 🐝</h6>
          </div>
        </div>
      </div>
    </>
  );
};


export default FooterExterno;
