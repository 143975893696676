import whatsapp from '../../../assets/images/icons/whatsapp.png';
import { FormattedMessage } from "react-intl";
import { isUserLoggedIn } from "../../../utility/Utils";

export const WhatsIcon = () => {

  const userData = JSON.parse(isUserLoggedIn());
  const userRole = userData ? userData.role : "USER";

return (
    <>
      <div className="bloco-ajuda" style={{display: userRole === "USER" ? "" : "none"}}>
      </div>
      <div className="ajuda" style={{display: userRole === "USER" ? "" : "none"}}>
      <FormattedMessage id="Help" defaultMessage="Needing help? .. Click here." />
      </div>     
    <a 
        href="https://api.whatsapp.com/send?phone=5541992669393&text=Olá,%20em%20que%20posso%20ajudar"
        target="_blank">
      <img style={{display: userRole === "USER" ? "" : "none"}} src={whatsapp} className="whatsapp"></img> 
    </a>
    </>
);

};